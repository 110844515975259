import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Images from "../components/portfolio/images"
//import Slider from "../components/portfolio/slider"

const Portfolio = () => (
  <Layout>
    <SEO title="Portfolio" />
    < Images />
  
    
  
    
    
  </Layout>
)

export default Portfolio;
