import React from 'react'
import {Container,Card, CardDeck} from 'react-bootstrap'


const Images = () => (
    <Container>
        <p></p>
        <p></p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h2>Our Portfolio</h2>
        <p></p>

        <CardDeck>
            <Card>
               
                    <Card.Img variant="top" src='https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109314/JRTileSolutions/IMG_8802_ba0bqm.jpg' />
    
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109314/JRTileSolutions/IMG_8803_2_sfw6ol.jpg" />
             
             
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109313/JRTileSolutions/IMG_8796_rfygkt.jpg" />
           
               
            </Card>
        </CardDeck>
        <p></p>
        <CardDeck>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109313/JRTileSolutions/IMG_8800_ovijny.jpg" />
              
               
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109313/JRTileSolutions/IMG_8798_aoifn0.jpg" />
             
             
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109313/JRTileSolutions/IMG_8801_qckddd.jpg" />
               
            </Card>
        </CardDeck>
        <p></p>



        <CardDeck>
            <Card>
               
                    <Card.Img variant="top" src='https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109312/JRTileSolutions/IMG_8790_airmky.jpg' />
    
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109312/JRTileSolutions/IMG_8797_misb23.jpg" />
                <Card.Body>
            
                </Card.Body>
             
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109311/JRTileSolutions/IMG_8785_zbepbw.jpg" />
                <Card.Body>
               
                </Card.Body>
               
            </Card>
        </CardDeck>
        <p></p>
        <CardDeck>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109311/JRTileSolutions/IMG_8787_b8yimr.jpg" />
          
               
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109311/JRTileSolutions/IMG_8793_r4aqac.jpg" />
          
             
            </Card>
            <Card>
                <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109315/JRTileSolutions/IMG_8812_i6rv9x.jpg" />
       
               
            </Card>
        </CardDeck>

        <br></br>
        <CardDeck>
            <Card>
               
            <Card.Img variant="top" src='https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109314/JRTileSolutions/IMG_8805_jlajud.jpg' />

                </Card>
                <Card>
                    <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109311/JRTileSolutions/IMG_8786_xf2ye5.jpg" />
                    <Card.Body>
                
                    </Card.Body>
                
                </Card>
                <Card>
                    <Card.Img variant="top" src="https://res.cloudinary.com/dvhnwdkun/image/upload/v1592109316/JRTileSolutions/IMG_8810_jmlvz8.jpg" />
                    <Card.Body>
                
                    </Card.Body>
                
                </Card>
            </CardDeck>
            <p></p>
         
        <br></br>
        <br></br>
    </Container>
)
export default Images;